body {
  background-color: #121212;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-color: #121212; /* Sexy dark background */
  color: #f5f5f5; /* Light text for contrast */
  font-family: 'Arial', sans-serif; /* Clean, modern font */
  font-size: 6rem; /* Make "óh" stand out */
  font-weight: 700; /* Bold text */
  letter-spacing: 0.1rem; /* Slight spacing for elegance */
}
